body {
  font-family: 'Poppins', sans-serif;
}

.container-fluid-background {
  background-image: url("assets/bg-pattern.png");
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #F5F7FF;
  background-size: cover;
}

.CenterObjects {
  display: flex;
  justify-content: center;
}

.Navbar {
  background-color: #4bb1f8;
  padding: 10px 5px;
}

/* Set store view */
.background {
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(126deg, #4bd0f8 24%, #61c5a7 98%);
}

.CloseIcon {
  color: white;
  font-size: 30px;
  margin-top: 40px;
  margin-right: 40px;
  border: 0;
}

.CloseIcon:hover {
  color: #4bb1f8;
  font-size: 30px;
  margin-top: 40px;
  margin-right: 40px;
  border: 0;
 }

.MainContainer {
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Title {
  font-weight: bold;
  color: white;
  margin-bottom: 50px;
}

.WhiteContainer {
  width: 400px;
  padding: 44px;
  border-radius: 24px;
  background-color: white;
}

.WhiteContainerFlex {
  width: 400px;
  padding: 44px;
  border-radius: 24px;
  background-color: white;
  margin-top: 30px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Select {
  display: flex;
  flex-direction: row;
}

.SelectOneStore {
  flex: 1;
  font-weight: bold;
  font-size: 18px;
}

.StoreDropdown {
  width: 310px;
}

.ConfirmButton {
  width: 400px;
  margin-top: 30px;
  padding: 18px;
  border-radius: 24px;
  border: solid 4px white;
  color: white;
  font-size: 24px;
}

.ConfirmButton:hover {
  width: 400px;
  margin-top: 30px;
  padding: 18px;
  border-radius: 24px;
  border: solid 4px white;
  background-color: white;
  color: #4bb1f8;
  font-size: 24px;
}

/* WelcomeCSD */
.WelcomeBackground {
  background-image: url("assets/welcome-bg.png");
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-color: #F5F7FF;
  background-size: cover;
}

.WelcomeWidget {
  width: 90%;
  background-color: white;
  border-radius: 24px;
  padding: 20px;
  box-shadow: 5px 10px 10px #4bd0f840;
}

/* Cash out process  */
.NumberSliderContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.NumberSliderItem {
  width: 20px;
  height: 20px;
  color: white;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline: 20px;
}

.WhiteContainerNoPadding {
  width: 400px;
  border-radius: 24px;
  background-color: white;
  margin-top: 30px;
  margin-bottom: 40px;
}

.GradientBG {
  width: 100%;
  background-image: linear-gradient(101deg, #4bb1f8, #61c5c0);
  border-radius: 24px 24px 0 0;
  color: white;
  padding: 10px 0;
}

.GrayBG {
  width: 100%;
  background-color: #536474;
  border-radius: 24px 24px 0 0;
  color: white;
  padding: 10px 0;
}

.Balance {
  padding-top: 30px;
  padding-bottom: 20px;
  font-size: 40px;
  font-weight: bold;
  color: #536474;
}

.AcceptButton {
  width: 400px;
  padding: 18px;
  border-radius: 24px;
  border: 0;
  color: white;
  font-size: 18px;
  background-color: #4bb1f8;
  box-shadow: 5px 10px 10px #4bd0f840;
}

.AcceptButton:hover {
  border: 0;
  width: 400px;
  padding: 18px;
  border-radius: 24px;
  color: white;
  font-size: 18px;
  background-color: #4bb1f8;
  box-shadow: 0 0 0 #4bd0f840;
 }

.CancelButton {
  width: 400px;
  padding: 18px;
  border-radius: 24px;
  border: 0;
  color: #536474;
  font-size: 16px;
  margin-top: 24px;
}

.GreenConfirmButton {
  width: 400px;
  padding: 18px;
  border-radius: 24px;
  border: 0;
  color: white;
  font-size: 24px;
  background-color: #61C5C0;
  box-shadow: 5px 10px 10px #61C5C040;
}

.GreenConfirmButton:hover {
  width: 400px;
  padding: 18px;
  border-radius: 24px;
  border: 0;
  color: white;
  font-size: 24px;
  background-color: #61C5C0;
  box-shadow: 0 0 0 #61C5C040;
}

.GreenLightConfirmButton {
  width: 400px;
  padding: 18px;
  border-radius: 24px;
  border: 0;
  color: white;
  font-size: 18px;
  background-color: #6FD19D;
  box-shadow: 5px 10px 10px #6FD19D40;
}

.GreenLightConfirmButton:hover {
  width: 400px;
  padding: 18px;
  border-radius: 24px;
  border: 0;
  color: white;
  font-size: 18px;
  background-color: #6FD19D;
  box-shadow: 0 0 0 #6FD19D40;
}

.SmallRoundContainer {
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  font-size: 28px;
}

/* Loading animation */
.Loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 2s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.no-border-button {
  border: 0;
}

.no-border-button:hover {
  border: 0;
}

.aside-widget:hover {
  text-shadow: 2px 2px 5px #9d9d9d;
}

.box-shadow-widget:hover {
  box-shadow: 2px 2px 5px #9d9d9d;
}